import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../scss/list.scss';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { machinesNames } from '../services/Controls';


function CrudPage({ title }) {

    let emptyProduct = {
        ID: null,
        LINE_NUMBER: '',
        PROCESS_ID: '',
        DESCRIPTION: '',
    };

    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        // getProducts().then(data => setProducts(data));
        const fetchCategories = async () => {
            try {
                setProducts(machinesNames)
                /*   await listApi(title).then((response)=>{
                       if(response.data.length>0){
                         setProducts(response.data);
                         //setSearch(response.data);
                       }else{
                         setProducts(null);
                        }	
                       }) */
                //  let res = await axios.get(baseUrl + title, { params: { erp_id } });
                // setData(res.data);
            } catch (err) {
                console.log(err);
            }
        }
        fetchCategories();
    }, []);

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }

    const saveProduct = () => {
        setSubmitted(true);

        if (product.LINE_NUMBER.trim()) {
            let _products = [...products];
            let _product = { ...product };
            if (product.ID) {
                const index = findIndexById(product.ID);

                _products[index] = _product;
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Machine Updated', life: 3000 });
            }
            else {
                _product.ID = createId();
                //   _product.image = 'product-placeholder.svg';
                _products.push(_product);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Machine Created', life: 3000 });
            }

            setProducts(_products);
            setProductDialog(false);
            setProduct(emptyProduct);
        }
    }

    const editProduct = (product) => {
        setProduct({ ...product });
        setProductDialog(true);
    }

    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    }

    const deleteProduct = () => {
        let _products = products.filter(val => val.ID !== product.ID);
        setProducts(_products);
        setDeleteProductDialog(false);
        setProduct(emptyProduct);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Machine Deleted', life: 3000 });
    }

    const findIndexById = (ID) => {
        let index = -1;
        for (let i = 0; i < products.length; i++) {
            if (products[i].ID === ID) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let ID = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            ID += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return ID;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const deleteSelectedProducts = () => {
        let _products = products.filter(val => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
    }

    const onInputChange = (e, LINE_NUMBER) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${LINE_NUMBER}`] = val;

        setProduct(_product);
    }

    /*
        const imageBodyTemplate = (rowData) => {
            return <img src={`demo/images/product/${rowData.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.image} className="w-7rem shadow-2" />
        }
    
        const priceBodyTemplate = (rowData) => {
            return formatCurrency(rowData.price);
        }
    
        const ratingBodyTemplate = (rowData) => {
            return <Rating value={rowData.rating} readOnly cancel={false} />;
        }
    
        const statusBodyTemplate = (rowData) => {
            return <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>;
        } */

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDeleteProduct(rowData)} />
            </React.Fragment>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
            <span className="p-input-icon-right w-full md:w-auto">

                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." className="w-full lg:w-auto" />
            </span>
            <div className="mt-3 md:mt-0 flex justify-content-end">
                <Button icon="pi pi-plus" className="mr-2 p-button-rounded" onClick={openNew} tooltip="New" tooltipOptions={{ position: 'bottom' }} />
                <Button icon="pi pi-trash" className="p-button-danger mr-2 p-button-rounded" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} tooltip="Delete" tooltipOptions={{ position: 'bottom' }} />
                <Button icon="pi pi-upload" className="p-button-help p-button-rounded" onClick={exportCSV} tooltip="Export" tooltipOptions={{ position: 'bottom' }} />
            </div>
        </div>
    );
    const productDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={saveProduct} />
        </React.Fragment>
    );

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
    );

    const deleteProductsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </React.Fragment>
    );

    return (
        <div className='list'>
            <div className='listContainer'>
                <div className="datatable-crud-demo surface-card p-4 border-round shadow-2">
                    <Toast ref={toast} />

                    <div className="text-3xl text-800 font-bold mb-4">CRUD Application for Machine</div>

                    <DataTable className='dataTable' ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="ID" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={globalFilter} header={header} responsiveLayout="scroll">
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} exportable={false}></Column>
                        <Column field="ID" header="ID" sortable style={{ minWidth: '12rem' }}></Column>
                        <Column field="LINE_NUMBER" header="LINE_NUMBER" sortable style={{ minWidth: '16rem' }}></Column>
                        <Column field="DESCRIPTION" header="DESCRIPTION" sortable style={{ minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
                    </DataTable>

                    <Dialog visible={productDialog} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '40vw' }} header="Machine Details" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="LINE_NUMBER">LINE_NUMBER</label>
                            <InputText id="LINE_NUMBER" value={product.LINE_NUMBER} onChange={(e) => onInputChange(e, 'LINE_NUMBER')} required autoFocus className={classNames({ 'p-invalid': submitted && !product.LINE_NUMBER })} />
                            {submitted && !product.LINE_NUMBER && <small className="p-error">Log Name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="PROCESS_ID">PROCESS_ID</label>
                            <InputTextarea id="PROCESS_ID" value={product.PROCESS_ID} onChange={(e) => onInputChange(e, 'PROCESS_ID')} required rows={3} cols={20} />
                        </div>
                        <div className="field">
                            <label htmlFor="DESCRIPTION">DESCRIPTION</label>
                            <InputTextarea id="DESCRIPTION" value={product.DESCRIPTION} onChange={(e) => onInputChange(e, 'DESCRIPTION')} required rows={3} cols={20} />
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {product && <span>Are you sure you want to delete <b>{product.LINE_NUMBER}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {product && <span>Are you sure you want to delete the selected products?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default CrudPage