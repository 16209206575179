import React from 'react';
import { useEffect, useState } from 'react';
import { controlText, machinesEffOpTimes } from '../../services/Controls';
import { getDataWithInputsApi, loadApi } from '../../services/appApi';
import moment from 'moment';
import EffOptimeChart from '../chart/EffOptimeChart';
import 'primeicons/primeicons.css';

function FormControlListSelector({ inputProcess, inputMachines }) {

  const [processId, setProcessId] = useState();
  const [machineId, setMachineId] = useState();
  const [machine, setMachines] = useState([]);
  //const [successFlag, setSucessFlag] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setCharData] = useState([]);
  const [targetEfficiency, setTargetEfficiency] = useState();
  const [wasDataFetchingSuccessful, setWasDataFetchingSuccessful] = useState(true);
  const [error, setError] = useState(false);

  const [data, setData] = useState([]);
  const [chartForm, setChartForm] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  //console.log(successFlag);
  /*
    const onChangeProcessValue  = async(e) =>  {
      setProcessId(e.target.value);
  
      await getDataWithInputsApi('GetMachinesEffByProcessId', processId).then((response) => {
        if (response.data.length > 0) {
          setSucessFlag(response.data[0]);
          setMachines(response.data[1]);
        
        }
      });
  
       // setMachines(inputMachines.filter((data) => data.mchineId == e.target.value));
    }
    */

  const handleLoadChart = async () => {
    /* 
    setIsLoading(true);
   console.log(data);
   let selectedMachine=machine.filter((dt) => dt.ID == data.p_machine_id); 
   console.log(selectedMachine);
   setChartForm({ ...chartForm, 'machineDesc':selectedMachine[0].DESCRIPTION?selectedMachine[0].DESCRIPTION:'Winding Machine #6',data});
   //console.log(chartForm);


    setWasDataFetchingSuccessful(false);
    setCharData(machinesEffOpTimes);

    console.log(chartData);
    setTargetEfficiency(74);
    setIsLoading(false);
 */

    let selectedMachine = machine.filter((dt) => dt.ID == data.p_machine_id);
    //console.log(selectedMachine);
    setIsLoading(true);
    setChartForm({ ...chartForm, 'machineDesc': selectedMachine[0].DESCRIPTION ? selectedMachine[0].DESCRIPTION : 'Winding Machine #6', data });
    //console.log(chartForm);
    await loadApi(data, "GetHourlyMachineEffOpTimeByIdAndTimespan").then(async (result) => {
      if (result) {
        let successFlag = result.data[0];

        if (successFlag === 'Y') {
          setCharData(await result.data[1]);
          setTargetEfficiency(await result.data[2]);

          setWasDataFetchingSuccessful(false);
        } else {
          setError(await result.data[2]);
        }
        setIsLoading(false);
      } else {
        setWasDataFetchingSuccessful(true);
        setIsLoading(false);
      }

    });
  }

  const onChangeProcessValue = async (e) => {
    //  setData({ ...data, [e.target.name]: e.target.value });
    e.preventDefault();
    setProcessId(e.target.value);
    setMachines(inputMachines.filter((data) => data.PROCESS_ID == e.target.value && data.PRODUCTION_SPEED != null && data.TARGET_EFFICIENCY != null && (data.ID < 3000 || data.ID >= 4000)));
  }

  //const currentDate = new Date('2023-08-04T12:00:00');
  // const [date, setDate] = useState(new Date());

  const handleChange = (e) => {
    e.preventDefault();
    console.log([e.target.name]);
    setData({ ...data, [e.target.name]: e.target.value });
    setMachineId(e.target.value);
    console.log(data);

  }

  const handleDateChange = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
    console.log(data);

  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setMachines(inputMachines.filter((data) => data.PROCESS_ID == 100));

        const date = new Date();
        const yesterday = moment().subtract(0, "days").set({
          hour: 7,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        let formattedStartDate = yesterday.format('yyyy-MM-DD HH:mm');
        setStartDate(formattedStartDate);

        const tommorrow = moment().add(1, "days").set({
          hour: 6,
          minute: 59,
          second: 0,
          millisecond: 0,
        });
        let formattedendDate = tommorrow.format('yyyy-MM-DD HH:mm');
        setEndDate(formattedendDate);
        setData({ ...data, 'p_machine_id': 2001, 'p_start_datetime': formattedStartDate, 'p_end_datetime': formattedendDate })
        //  setChartForm({ ...chartForm, 'machineDesc':'Winding Machine #6', data});
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, [inputMachines]);

  return (
    <div id="controls" >
      <div className="row justify-content-center m-0">

        <div className='col-auto px-1 d-flex flex-column'>

          <label className={controlText.length === 1 ? "form-label mb-3" : "form-label"}>
            Process
          </label>
          <div className="form-check" onChange={onChangeProcessValue}>
            {inputProcess.map((input, index) => (
              <div key={input.ID}>
                <input className="form-check-input" type="radio"
                  id="p_process_id"
                  name="p_process_id"
                  value={input.ID}
                  checked={processId ? processId == input.ID ? true : '' : index == 0 ? true : ''}
                />
                <label className="form-check-label"
                  for="processItemPrefix">
                  {input.DESCRIPTION}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className='col-auto px-1 d-flex flex-column'>
          <label className="form-label" for="controlItemSelect">
            Machine
          </label>
          {processId == 400 ?
            <div className="form-check" onChange={handleChange}>
              {machine.map((input, index) => (
                <div>
                  <input className="form-check-input" type="radio" id="p_machine_id" name="p_machine_id"
                    value={input.ID}
                    checked={machineId ? machineId == input.ID ? true : '' : index == 0 ? true : ''}
                  />
                  <label className="form-check-label"
                    for="processItemPrefix">
                    {input.DESCRIPTION}
                  </label>
                </div>
              ))}
            </div> :
            <select className="form-select"
              id="p_machine_id"
              name="p_machine_id"
              onChange={handleChange} >
              {machine.map((option) => (
                <option name="selectItem" value={option.ID}>
                  {option.DESCRIPTION}
                </option>
              ))}
            </select>
          }
        </div>

        <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
          <label className="form-label">
            Start Date
          </label>
          <input type="datetime-local" name='p_start_datetime' defaultValue={startDate} onChange={handleDateChange} />

        </div>
        <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
          <label className="form-label">
            End Date
          </label>
          <input type="datetime-local" name='p_end_datetime' defaultValue={endDate} onChange={handleDateChange} />
        </div>

        <div className="col-auto form-group my-4 ps-1 pe-8 d-flex flex-column">
          <button type="button" className="btn btn-primary"
            id="chartAreaId" onClick={handleLoadChart}
          >
            <i className={isLoading ? "pi pi-spin pi-spinner" : "pi pi-spin"}></i>
            <span> {isLoading ? "Loading" : "Load"} </span>
          </button>
        </div>
      </div>

      {error ?
        <div className="row mt-8 justify-content-md-center">
          <div className="col-12">
            <div className="card border-secondary">
              <div className="card-header bg-dark text-white">
                Machine Efficiency By Operation Time Charts are Unavailable
              </div>
              <div className="card-body card-body-error rounded-bottom">
                <ul>
                  <li v-for="error in loadingErrors">
                    {error}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        : ''
      }
      {!wasDataFetchingSuccessful ?
        <div className="row mt-8 my-5 justify-content-md-center">
          <div className="col">
            <EffOptimeChart chartData={chartData} chartForm={chartForm} targetEff={targetEfficiency} id="OptimeChart1" />
          </div>
        </div>
        : ''
      }
    </div>
  )
}

export default FormControlListSelector