//export const baseUrl =  'http://89.116.187.234:8000';
export const baseUrl = 'http://localhost:3001';
// export const baseUrl =  'http://10.132.200.77:31553';
 //export const baseUrl =  'http://localhost:31553';
/*
axios.post('http://89.116.187.234:3002/' + title, data).then((result) => {
      if (result) {
       //result
      } else {
        // no result
      }
    }); */