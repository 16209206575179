
import '../scss/dashboard.scss';
import { useEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import FormControlListSelector from '../components/controls/FormControlListSelector';
import { getDataApi } from '../services/appApi';

function EffOpTimeChartPage() {

  const [process, setProcess] = useState([]);
  const [machines, setMachines] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [areMultipleChartAreasShown, setareMultipleChartAreasShown] = useState(false);


  const [error, setError] = useState(false);
  const toggleSecondChartArea = () => {
    setareMultipleChartAreasShown(!areMultipleChartAreasShown);
  }

  /*
    useEffect(() => {
       const fetchData = async () => {
         try {
           setIsLoading(true);
        let res =1;
           if(res){
              setProcess(processItem)
             setMachines(machinesNames);
               setIsLoading(false);  
           }else{
            setIsLoading(false);
           }
           
         } catch (err) {
           console.log(err);
           setError(err);
         }
       }
       fetchData();
     }, []);

*/

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        let res = await getDataApi("EffOpTimePageLoad");
        if (res) {
          let successFlag = res.data[0];
          if (successFlag === 'Y') {
            setProcess(await res.data[1]);
            setMachines(await res.data[2]);
            setIsLoading(false);
          } else {
            setError(await res.data[2]);
          }
        } else {
          setIsLoading(false);
        }

      } catch (err) {
        console.log(err);
        setError(err);
      }
    }
    fetchData();
  }, []);

  return (
    <div className='dashboard'>
      <div className='dashboardContainer'>

        <div className="charts" id="app">
          {isLoading ?
            <div className="row d-flex justify-content-center m-5">
              <div className="col-auto">
                <div className="card shadow-sm border-primary">
                  <div className="card-header bg-primary text-white">
                    <h2 className="mb-0">
                      Machine Efficiency By Operation Time Chart
                    </h2>
                  </div>
                  <div className="card-body text-center">
                    <h3>
                      <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i> The page is loading...
                    </h3>
                  </div>
                </div>
              </div>
            </div> : ''
          }
          {!isLoading ?
            <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center" >
              Machine Efficiency By Operation Time Chart
              <button type="button" id="btnSecondChart" disabled
                className={areMultipleChartAreasShown ? "btn btn-success mt-2 mt-md-0 ms-md-3 btn-danger" : "btn btn-success mt-2 mt-md-0 ms-md-3 btn-success"}
                onClick={toggleSecondChartArea}
              >
                <i className={areMultipleChartAreasShown ? " fa-duotone fa-fw fa-square-minus" : "fa-duotone fa-fw fa-square-plus"}></i>
                {areMultipleChartAreasShown ? "Remove " : "Add "} Add 2<sup>nd</sup> Chart
              </button>
            </h2> : ''}

          <div className="row">
            <div className="col">
              <div className="row justify-content-center">
                <div className="col-auto ps-0 pe-2">
                  {!isLoading ? <FormControlListSelector inputProcess={process} inputMachines={machines} /> : ''}
                </div>
              </div>
            </div>
            {
              areMultipleChartAreasShown ?
                <div className="col">
                  <div className="row justify-content-center">
                    <div className="col-auto ps-0 pe-2">
                      {!isLoading ? <FormControlListSelector inputProcess={process} inputMachines={machines} /> : ''}
                    </div>

                  </div>
                </div> : ''
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default EffOpTimeChartPage;